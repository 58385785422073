const theme = {

  colors: {
    text: "#191919",
    primary: "#32a6b7",
    secondary: "#fad200",
    background: "#fff",
    black: "#191919",
    blue: "#2149cc",
    bluelight: "#e8ecfa",
    graydark: "#ebebeb",
    graylight: "#fafafa",
    teal: "#32a6b7",
    tealdark: "#2695a5",
    teallight: "#e4f3f6",
    orange: "#e97809",
    orangelight: "#fdf1e6",
    red: "#c1272c",
    white: "#ffffff",
    yellow: "#fad200",
    yellowlight: "#fef8e5",
  },

  breakpoints: [
    '600px',
    '900px',
    '1200px',
    '1800px'
  ],

  space: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    18: "4.5rem",
    20: "5rem",
    22: "5.5rem",
    24: "6rem",
    26: "6.5rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
  },

  sizes: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    18: "4.5rem",
    20: "5rem",
    22: "5.5rem",
    24: "6rem",
    26: "6.5rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    auto: "auto",
    fifth: "20%",
    quarter: "25%",
    third: "33.333333%",
    half: "50%",
    twoThirds: "66.666667%",
    threeQuarters: "75%",
    fourFifths: "80%",
    full: "100%",
    screen: "100vw",
    container: "1200px",
  },

  radii: [
    0,
    2,
    4,
    8,
    16,
    25,
    75,
    9999
  ],
  
  fonts: {
    body: 'freight-sans-pro, sans-serif',
    heading: "'Mirador', serif",
    monospace: '"Menlo, monospace"',
  },

  fontSizes: {
    0: 20,
    1: 24,
    2: 30,
    3: 42,
    4: 54,
    5: 72,
    6: 110,
    sm: 20,
    md: 24,
    lg: 30,
    xl: 42,
    "2xl": 54,
    "3xl": 72,
    "4xl": 110,
  },

  fontWeights: {
    body: 400,
    heading: 700,
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  lineHeights: {
    1: "1rem",
    2: "1.25rem",
    3: "1.5rem",
    4: "1.75rem",
    5: "2rem",
    6: "2.25rem",
    7: "2.5rem",
    8: "2.75rem",
    9: "3rem",
    10: "3.25rem",
    body: "1.75",
    heading: 1,
  },
  
  shadows: {
    small: '4px 4px 0 rgb(0,0,0)',
    large: '7px 7px 0 rgb(0,0,0)',
    faint: '0 0 20px 0 rgba(0,0,0,0.1)',
  },

  styles: {
    root: {
      overflowX: "hidden",
      overflowY: "scroll",
      fontSize: 20,
      fontFamily: "body",
    },
    a: {
      color: "black",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  text: {
    default: {
      m: 0,
      mb: 6,
      position: "relative",
      fontFamily: "body",
      lineHeight: "body",
      zIndex: 50,
      ul: {
        mt: 0,
      },
    },
    text: {
      md: {
        variant: "text.default",
        fontSize: 1,
        lineHeight: 4,
      },
      lg: {
        variant: "text.default",
        fontSize: 2,
        lineHeight: "1.25"
      },
      xl: {
        variant: "text.default",
        fontSize: 3,
      },
      "2xl": {
        variant: "text.default",
        fontSize: 4,
      },
      "3xl": {
        variant: "text.default",
        mb: 6,
        fontSize: 5,
      },
      "4xl": {
        variant: "text.default",
        fontSize: 6,
      },
    },
    head: {
      base: {
        position: "relative",
        display: "block",
        lineHeight: "heading",
        zIndex: 50,
        div: {
          whiteSpace: "nowrap",
        },
      },
      sm: {
        variant: "text.head.base",
        fontSize: 0,
      },
      md: {
        variant: "text.head.base",
        mb: 4,
        fontSize: 1,
        lineHeight: 4,
      },
      lg: {
        variant: "text.head.base",
        fontSize: 2,
        lineHeight: "1.25"
      },
      xl: {
        variant: "text.head.base",
        mb: 6,
        fontSize: 3,
      },
      "2xl": {
        variant: "text.head.base",
        fontSize: 4,
      },
      "3xl": {
        variant: "text.head.base",
        mb: 6,
        fontSize: 5,
      },
      "4xl": {
        variant: "text.head.base",
        mb: 8,
        fontSize: 6,
      },
    },
    navActive: {
      position: "relative",
      ul: {
        display: "block !important",
      },
      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        width: "9px",
        height: "9px",
        top: "14px",
        left: "-17px",
        borderRadius: 9999,
        backgroundColor: "teal",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
      justify: {
        textAlign: "justify", 
      },
    },
    badge:{
      normal: {
        variant: "text.default",
        display: "block",
        mb: 0,
        fontSize: 1,
        lineHeight: "1.1",
      },
      strong: {
        variant: "text.badge.normal",
        fontWeight: "bold",
        fontSize: 4,
        lineHeight: "1.3",
      },
    },
    window: {
      head: {
        variant: "text.head.base",
        px: 5,
        pt: 4,
        pb: 2,
        color: "white",
        fontSize: 1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      headControl: {
        variant: "text.window.head",
        position: "relative",
        "&:before": {
          content: "'...'",
          position: "absolute",
          display: "block",
          top: "6px",
          right: "20px",
          color: "white",
          fontSize: 5,
          fontWeight: "body",
          letterSpacing: "10px",
          lineHeight: "1",
          textAlign: "center",
          opacity: 0.3,
        },
      },
      text: {
        variant: "text.default",
        pt: 4,
        px: 5,
      },
    },
    card: {
      title: {
        variant: "text.text.lg",
        color: "teal",
        my: 2,
      },
      text: {
        variant: "text.default",
        mb: 0,
      },
    },
    intro: {
      hero: {
        primary: {
          variant: "text.head.4xl",
          mb: 8,
        },
        secondary: {
          variant: "text.text.lg",
          mb: 2,
          fontWeight: "bold"
        },
      },
    },
    search: {
      links: {
        text: {
          variant: "text.body",
          display: "block",
        }
      },
      content: {
        menuDefault: {
          mb: 2,
          cursor: "pointer",
          textDecoration: "underline",
        },
        menuActive: {
          mb: 2,
        },
      },
    },
    team: {
      leadDesc: {
        pb: 3,
        maxWidth: "140px",
        fontSize: 1,
        fontStyle: "italic",
        fontWeight: "bold",
        textAlign: "center",
        borderBottomColor: "yellow",
        borderBottomWidth: "5px",
        borderBottomStyle: "solid",
      }
    }
  },

  grids: {
    default: {
      gridGap: [2,4,6],
      width: "auto",
    },
    sideLeft: {
      variant: "grids.default",
      gridTemplateColumns: ['1fr', '1fr', '250px 1fr'],
    },
    sideRight: {
      variant: "grids.default",
      gridTemplateColumns: ['1fr', '1fr', '1fr 250px'],
    },    
    sideLeftWide: {
      variant: "grids.default",
      gridGap: [6, 14],
      gridTemplateColumns: ['1fr', '340px 1fr'],
    },
    smallSplit: {
      variant: "grids.default",
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
    },
    evenSplit: {
      variant: "grids.default",
      gridTemplateColumns: ['1fr', '1fr 1fr'],
    },
    bigSplit: {
      variant: "grids.default",
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
      gridGap: [4,6,8],
    },
    featuredLeft: {
      variant: "grids.sideLeft",
      my: [0, 0, 4],
      mx: [0, 0, 4],
      gridGap: [0, 0, 4],
      gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
    },
    featuredRight: {
      variant: "grids.sideRight",
      my: [0, 0, 4],
      mx: [0, 0, 4],
      gridGap: [0, 0, 4],
      gridTemplateColumns: ['1fr', '1fr', '1fr 240px'],
    },
    cardsTwo: {
      variant: "grids.default",
      mb: 6,
      gridGap: 6,
      gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
    },
    cardsThree: {
      variant: "grids.default",
      mb: 6,
      gridGap: 6,
      gridTemplateColumns: ["1fr", "1fr", "1fr 1fr 1fr"],
    },
    intro: {
      hero: {
        variant: "grids.bigSplit",
        pb: [5, 5, 10],
      },
      compare: {
        table: {
          width: "auto",
          px: [4, 4, 7],
          py: 4,
          gridGap: [0,0,"0 1em"],
          gridTemplateColumns: ["auto 100px 100px", "auto 25% 25%", "auto 220px 220px"],
          bg: "blue",
          borderRadius: 4,
        },
      },
    },
    website: {
      sommersText: {
        variant: "grids.default",
        gridTemplateColumns: ["1fr", "1fr 25%", "1fr 320px"]
      },
    },
    search: {
      links: {
        variant: "grids.default",
        gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
        gridGap: [0, 0, 20],
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          top: "20px",
          left: ["105%", "100%", "50%"],
          bottom: "120px",
          width: "2px",
          bg: "teal",
        },
      },
    },
    social: {
      logos: {
        three: {
          variant: "grids.default",
          gridTemplateColumns: "1fr 1fr 1fr",
        },
        four: {
          variant: "grids.default",
        },
      },
    },
  },

  badges: {
    primary: {
      position: "relative",
      fontFamily: "body",
      lineHeight: "1.2",
      borderRadius: 9999,
      whiteSpace: "normal",
      fontVariantNumeric: "lining-nums",
      zIndex: 50,
    },
    slim: {
      variant: "badges.primary",
      px: 4,
      py: 2,
      minWidth: "180px",
      lineHeight: "1.2em",
      textAlign: "center",
      textTransform: "uppercase",
      boxShadow: "small",
    },    
    simple: {
      variant: "badges.primary",
      px: 7,
      py: 3,
      mt: 2,
      mb: 4,
      mx: "auto",
      lineHeight: "1.2em",
      textAlign: "center",
      textTransform: "uppercase",
    },
    shaded: {
      variant: "badges.simple",
      px: 7,
      py: 5,
      fontSize: 1,
      border: "1px solid black",
      boxShadow: "small",
      strong: {
        fontSize: 4,
        lineHeight: "36px",
      },
    },
    circle: {
      base: {
        variant: "badges.shaded",
        px: "0",
        py: 3,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "125px",
        aspectRatio: "1 / 1",
      },
      number: {
        variant: "badges.circle.base",
        p: 0,
        color: "white",
        fontSize: 2,
        bg: "black",
        width: "45px",
        boxShadow: "none",
      },
    },
    outliner: {
      base: {
        variant: "badges.simple",
        position: "relative",
        mx: ["auto", null, "0"],
        my: "20px",
        display: "block",
        width: "max-content",
        pt: 2,
        pr: 8,
        pb: 5,
        pl: 6,
        fontSize: 1,
        fontWeight: "bold",
        textAlign: "center",
        bg: "graydark",
        border: "none",
        boxShadow: "none",
      },
      baseBefore: {
        variant: "badges.primary",
        content: "''",
        position: "absolute",
        display: "block",
        top: "-9px",
        left: "-9px",
        height: "100%",
        width: "100%",
        pointerEvents: "none",
        zIndex: 20,
      },
      gray: {
        variant: "badges.outliner.base",
        color: "black",
        bg: "graydark",
        "::before": {
          variant: "badges.outliner.baseBefore",
          border: "1px solid black",
        },
      },
      blue: {
        variant: "badges.outliner.base",
        color: "white",
        bg: "blue",
        "::before": {
          variant: "badges.outliner.baseBefore",
          border: "1px solid white",
        },
      },
      withIcon: {
        variant: "badges.outliner.gray",
        display: "flex",
        pt: 2,
        pl: 4,
        pb: 6,
        minWidth: 320,
        width: "initial",
        fontSize: 0,
        fontWeight: "normal",
        textTransform: "none",
        "svg": {
          position: "absolute",
          top: 2,
          right: 8,
          zIndex: 20, 
        },
      },
    },    
    check: {
      variant: "badges.simple",
      display: "flex",
      alignItems: "center",
      minHeight: "72px",
      width: "100%", 
      padding: 3,
      color: "black",
      fontWeight: "normal",
      lineHeight: "1.1",
      textAlign: "center",
      textTransform: "none",
      bg: "rgba(255,255,255,0.9)",
      border: "1px solid #a1b1b3",
      svg: {
        m: 0,
        mr: 1,
        minWidth: "30px",
      }
    },
    dots: {
      prevNext: {},
      prev: {},
      next: {},
      dot: {
        display: "inline-block",
        width: "15px",
        height: "15px",
        p: 0,
        m: 1, 
        cursor: "pointer",
        borderRadius: "100%",
      },
    },
    intro: {
      hero: {
        big: {
          variant: "badges.shaded",
          display: "block",
          px: 8,
          py: 6,
          fontSize: 3,
          color: "black",
          bg: "yellow",
          boxShadow: "large",
        },
        small: {
          variant: "badges.shaded",
          px: 10,
          py: 5,
          fontSize: 1,
          fontWeight: "normal",
          textTransform: "none",
          bg: "blue",
          maxWidth: "419px",
        },
      },
    },
    search: {
      dev: {
        circle: {
          variant: "badges.circle.base",
          minWidth: "324px",
          color: "black",
          lineHeight: 5,
          textTransform: "initial",
          bg: "#f0f0f0",
          border: "4px solid #e3e3e3",
          boxShadow: "none",
        },
      },
    },
    team: {
      moreLeads: {
        variant: "badges.shaded",
        px: 10,
        py: 5,
        fontSize: 1,
      },
      moreCircle: {
        variant: "badges.circle.base",
        minWidth: "155px",
        bg: "blue",
        fontVariantNumeric: "normal",
      }
    },
    next: {
      training: {
        circle: {
          variant: "badges.circle.base",
          width: "157px",
        },
      },
    },
  },

  cards: {
    divider: {
      base: {
        display: "block",
        mx: [6, 6, 0],
        pt: 10,
        pb: 3,
        px: "12%",
        textAlign: "center",
        borderRadius: 5,
        span: {
          display: "inline",
        },
      },
      teal: {
        variant: "cards.divider.base",
        color: "white",
        bg: "teal",
      },
      yellow: {
        variant: "cards.divider.base",
        color: "black",
        bg: "yellow",
      },
    },
    simple: {
      px: 6,
      py: 5,
      textAlign: "left",
      bg: "rgba(255,255,255,0.9)",
      border: "1px solid #a1b1b3",
      borderRadius: 4,
      p: {
        m: 0,
      },
      ul: {
        mt: 0,
        ml: 0,
      }
    },
    featured: {
      mx: 0,
      my: 8,
      px: 8,
      pt: [6, 8],
      pb: [6, 5],
      borderRadius: 4,
    },
    shadow: {
      px: 6,
      py: 5,
      bg: "white",
      borderRadius: 4,
      boxShadow: "faint",
    },
    window: {
      borderRadius: 4,
      boxShadow: "faint",
    },
    check: {
      variant: "cards.simple",
      pl: 3,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      svg: {
        mt: "10px",
        minWidth: "40px",
      }
    },
    search: {
      links: {
        cardBase: {
          borderRadius: "20px",
          border: "1px solid #0f1111",
        },
        outer: {
          variant: "cards.search.links.cardBase",
        },
        inner: {
          variant: "cards.search.links.cardBase",
          bg: "white",
        },
      },
    },
    email: {
      tearDrop: {
        pt: 6,
        pb: 2,
        px: 4,
        bg: "graydark",
        border: "1px solid #000",
        borderRadius: 4,
        borderTopLeftRadius: 0,
        boxShadow: "small",
      },
    },
  },

  variants: {
    row: {
      base: {
        px: [8, 6, 4],
        py: [6, 8],
      },
      sm: {
        variant: "variants.row.base",
        py: [3, 5],
      },
      md: {
        variant: "variants.row.base",
        pt: [8, 16],
        pb: [8, 10],
      },
      lg: {
        variant: "variants.row.base",
        py: [10, 12, 16],
      },
    },
    column: {
      main: {
        px: [0, 6],
      },
      copy: {
        variant: "variants.column.main",
        pr: [0, 6, 10],
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
      justify: {
        textAlign: "justify", 
      },
    },
    floating: {
      base: {
        position: "relative",
      },
      sub: {
        position: "absolute",
        top: "20px",
        left: "calc(50% - 20px)",      
      },
      code: {
        orange: {
          variant: "variants.floating.base",
          "&>div": {
            variant: "variants.floating.sub",
            top: "-40px",
            left: "120px",
          },
        },
      },
      mouse: {
        variant: "variants.floating.base",
        "&>div": {
          variant: "variants.floating.sub",
          top: "25px",
          left: "100%",
          height: "max-content",
          width: "max-content",
        },
      },
      cardDot: {
        variant: "variants.floating.base",
        svg: {
          variant: "variants.floating.sub",
          top: "25px",
          left: "auto",
          right: "0",      
        },
      },
      cardIcon: {
        variant: "variants.floating.base",
        svg: {
          variant: "variants.floating.sub",
          top: "-10px",
          left: "auto",
          right: "-10px",
        },
      },
      cardUp: {
        one: {
          variant: "variants.floating.base",
          svg: {
            variant: "variants.floating.sub",
            top: "0",
            left: "0",
          },
        },
        two: {
          variant: "variants.floating.base",
          svg: {
            variant: "variants.floating.sub",
            top: "40px",
            left: "-80px",
          },
        },
        three: {
          variant: "variants.floating.base",
          svg: {
            variant: "variants.floating.sub",
            top: "80px",
            left: "-160px",
          },
        },
      },
      divider: {
        variant: "variants.floating.base",
        svg: {
          variant: "variants.floating.sub",
          top: "0",
        },
      },
      import: {
        variant: "variants.floating.base",
        svg: {
          variant: "variants.floating.sub",
          top: "-30px",
          left: "75%",
        },
      },
      section: {
        variant: "variants.floating.base",
        svg: {
          variant: "variants.floating.sub",
          top: "20px",
        },
      },
      search: {
        strategy: {
          monthly: {
            video: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "0",
                left: "0",
              },
            },
            image: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "80px",
                left: "100%",
              },
            },
            waves: {
              variant: "variants.floating.base",
              "svg, img": {
                variant: "variants.floating.sub",
                top: "100px",
                left: "80px",
              },
            },
          },
          links: {
            teal: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: 0,
                left: "auto",
                right: "-40px",
              },
            },
            yellow: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "40px",
                left: "40px",
                zIndex: 10,
              },
            },
          },
        },
        dev: {
          longForm: {
            variant: "variants.floating.base",
            "&>div": {
              variant: "variants.floating.sub",
              top: "0",
              left: "-166%",
            },
          },
          webStory: {
            back: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "0",
                left: "125px",
              },
            },
            front: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "42px",
                left: "230px",
              },
            },
          },
          video: {
            player: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "40px",
                left: "-10px",
              },
            },
            image: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "140px",
                left: "-70px",
              },
            },
          },
          qanda:{
            teal: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "-150px",
                left: "auto",
                right: "50px",
              },
            },
            blue: {
              variant: "variants.floating.base",
              "&>div": {
                variant: "variants.floating.sub",
                top: "-95px",
                left: "auto",
                right: "-20px",
              },
            },
          },
          ebook: {
            variant: "variants.floating.base",
            "&>div": {
              variant: "variants.floating.sub",
              top: "auto",
              left: "auto",
              right: "90px",
              bottom: "-80px",
            },
          },
        },
        links: {
          mediumPlay: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "-22px",
              left: "72%",
            },
          },
          mediumMountain: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "133px",
              left: "-22px",
            },
          },
          maximumLink: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "-19px",
              left: "66%",
            },
          },
          someYelp: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "99px",
              left: "-22px",
            },
          },
          someAnnounce: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "220px",
              left: "77%",
            },
          },
          highGraduate: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "-22px",
              left: "69%",
            },    
          },
        },
        together: {
          arrowLong: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "0",
              left: "auto",
              right: "0px",
              transform: "rotate(180deg)",
            },
          },
          circlePlay: {
            variant: "variants.floating.base",
            svg: {
              variant: "variants.floating.sub",
              top: "35px",
              left: "-22px",
              zIndex: 10,
            },
          },
        },
      },
      social: {
        pressSchwartz1: {
          variant: "variants.floating.base",
          "&>div": {
            variant: "variants.floating.sub",
            top: "0",
            left: "auto",
          },
        },
        pressSchwartz2: {
          variant: "variants.floating.base",
          "&>div": {
            variant: "variants.floating.sub",
            top: "50px",
            left: "auto",
            right: "0",
          },
        },
      },
      email: {
        arrowRight: {
          variant: "variants.floating.base",
          svg: {
            variant: "variants.floating.sub",
            top: "42px",
            left: "106%",
            transform: "rotate(-90deg)"
          },
        },
        arrowDown: {
          variant: "variants.floating.base",
          svg: {
            variant: "variants.floating.sub",
            top: "180px",
            left: "70%",
          },
        },
      },
    },
    image: {
      badge: {
        variant: "variants.image.card",
        borderRadius: "9999px",
      },
      shadow: {
        mb: 2,
        display: "inline-flex",
        boxShadow: "faint",
      },
      card: {
        display: "inline-flex",
        mb: 2,
        borderRadius: 4,
        boxShadow: "faint",
        flexDirection: "column",
        "img, svg": {
          borderRadius: 4,
          width: "100%",
        },
      },
      leaf: {
        borderTopLeftRadius: "25%",
        borderBottomRightRadius: "25%",
        "img, svg": {
          borderTopLeftRadius: "25%",
          borderBottomRightRadius: "25%",
        },
      },
      round: {
        display: "inline-flex",
        borderRadius: 4,
        "img, svg": {
          borderRadius: 4,
        },
      },
      stroke: {
        mb: 2,
        display: "inline-flex",
        borderRadius: 4,
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "graydark",
        "img, svg": {
          borderRadius: 4,
        },
      },
      topLeft: {
        variant: "variants.image.card",
        borderTopLeftRadius: 0,
        "img, svg": {
          borderTopLeftRadius: 0,
        },
      },
      topLeftRight: {
        borderTopLeftRadius: "150px",
        borderTopRightRadius: "150px",
        "img, svg": {
          borderTopLeftRadius: "150px",
          borderTopRightRadius: "150px",
        },
      },
    },
    nav: {
      variant: "text.default",
      position: "sticky",
      display: ["flex", "flex", "block"],
      alignItems: "center",
      flexDirection: "column",
      top: 3,
      ul: {
        ml: 6,
        p: 0,
        lineHeight: "1.75",
        listStyle: "none",
        li: {
          a: {
            color: "black",
            textDecoration: "none",
          },
          ul: {
            display: "none",
            my: 0,
            ml: 3,
            a: {
              color: "teal",
            },
          },
        },
      },
    },
    dots: {
      container: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      content: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        p: {
          pt: 2,
          mb: 0,
        }
      },
      control: {
        mt: 4,
      }
    },
    tilted: {
      shape: {
        width: "250px",
        height: "250px",
        bg: "tealdark",
        borderTopLeftRadius: "25%",
        borderBottomRightRadius: "25%"
      },
    },
    intro: {
      hero: {
        row: {
          variant: "variants.row.sm",
          color: "white",
          bg: "teal",
        },
        logo: {
          pb: [5,5,10],
          textAlign: "center"
        },
      },
      compare: {
        base: {
          variant: "text.default",
          my: 0,
        },
        titleSpan: {
          mx: 3,
          py: 1,
          height: "full",
        },
        title: {
          variant: "variants.intro.compare.base",
          color: "white",
          div: {
            variant: "variants.intro.compare.titleSpan",
            borderBottom: "1px solid #4566d4",
          },
        },
        full: {
          variant: "variants.intro.compare.base",
          textAlign: "center",
          bg: "#f4f6fc",
          div: {
            variant: "variants.intro.compare.titleSpan",
            borderBottom: "1px solid #d8dff5",
            textAlign: "center",
          },
        },
        sequoia: {
          variant: "variants.intro.compare.base",
          textAlign: "center",
          bg: "#c7d1f2",
          div: {
            variant: "variants.intro.compare.titleSpan",
            borderBottom: "1px solid #b1bfed",
            textAlign: "center",
          },
        },
      },
    },
    search: {
      links: {
        dot: {
          base: {
            position: "relative",
          },
          before: {
            content: '""',
            position: "absolute",
            top: "calc(50% - 10px)",
            display: "block",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            bg: "teal",
          },
          left: {
            variant: "variants.search.links.dot.base",
            "&:before": {
              variant: "variants.search.links.dot.before",
              left: "-59px",
            },
          },
          leftTop: {
            variant: "variants.search.links.dot.base",
            "&:before": {
              variant: "variants.search.links.dot.before",
              top: "10px",
              left: "-59px",
            },
          },
          right: {
            variant: "variants.search.links.dot.base",
            "&:before": {
              variant: "variants.search.links.dot.before",
              left: "auto",
              right: "-61px",
            },
          },
        },
      },
    },
    ad: {
      circleOnBottom: {
        position: "relative",
        p: {
          mb: 0,
        },
      },
    }
  },

  tilted: {
    wrapper: {
      transform: "scale(.6)",
      "@media (min-width: 600px)": {
        transform: "scale(.8)"
      },
      "@media (min-width: 900px)": {
        transform: "scale(1)"
      },
    },
    containers: {
      introHero: {
        minHeight: 500,
      },
      introMission: {
        minHeight: 566,
      },
      webCore: {
        minHeight: 333,
      },
      webCustom: {
        minHeight: 333,
      },
      webSommers: {
        minHeight: 555,
      },
      webSequoia: {
        minHeight: 333,
      },
      local: {
        minHeight: 600,
      },
      searchStrategy: {
        minHeight: 555,
      },
      searchCompetitor: {
        minHeight: 333,
      },
      searchSpy: {
        minHeight: 333,
      },
      searchPoints: {
        minHeight: 433,
      },
      searchDev: {
        minHeight: 700,
      },
      socialUpgrade: {
        minHeight: 333,
      },
      socialPress: {
        minHeight: 555,
      },
      dashboard: {
        minHeight: 333,
      },
      nextSteps: {
        minHeight: 333,
      },
      nextBrand: {
        minHeight: 444,
      },
      nextIDkit: {
        minHeight: 444,
      },
    },
    layers: {
      introHeroCenterpiece: {
        whileInView: {
          translateX: "-33%",
        },    
      },
      introHeroButton: {
        whileInView: {
          translateX: "-75%",
          translateY: "-75%",
          translateZ: 100,
        },
      },
      introHeroRightArrows: {
        whileInView: {
          translateX: "-215%",
          translateY: "-415%",
          translateZ: -100
        },
      },
      introHeroLeftArrows: {
        whileInView: {
          translateX: "181%",
          translateY: "464%",
          translateZ: -100
        },
      },
      introHeroWaves: {
        whileInView: {
          translateX: "-499%",
          translateY: "229%",
          translateZ: -100
        },
      },
      introHeroTopShape: {
        whileInView: {
          translateX: "33%",
          translateY: "-105%",
          translateZ: -150
        },
      },
      introHeroBottomShape: {
        whileInView: {
          translateX: "-66%",
          translateY: "0",
          translateZ: -100
        },
      },
      introMissionCenterpiece: {
        whileInView: {
          translateZ: -50
        },
      },
      introMissionChat: {
        whileInView: {
          translateY: "-175%",
        },
      },
      introMissionBio: {
        whileInView: {
          translateX: "20%",
          translateY: "-90%",
          translateZ: 50
        },
      },
      introMissionVideo: {
        whileInView: {
          translateX: "-125%",
          translateZ: 50
        },
      },
      introMissionLinkedin: {
        whileInView: {
          translateX: "-175%",
          translateY: "100%",
        },
      },
      introMissionWaves: {
        whileInView: {
          translateX: "-159%",
          translateY: "-66%",
          translateZ: -100
        },
      },
      webCoreCenterpiece: {
        whileInView: {
          translateX: "-70%",
        },
      },
      webCoreConversionRate: {
        whileInView: {
          translateX: "-46%",
          translateY: "66%",
          translateZ: 50,
        },
      },
      webCoreShape: {
        whileInView: {
          translateX: "-55%",
          translateY: "-10%",
          translateZ: -50,
        },
      },
      webCustomCenterpiece: {
        whileInView: {
          translateX: "-37%",
          translateY: "-75%",
        },
      },
      webCustomBadge: {
        whileInView: {
          translateX: "-87%",
          translateY: "-75%",
          translateZ: 100,
        },
      },
      webCustomViews: {
        whileInView: {
          translateX: "-30%",
          translateY: "30%",
          translateZ: 50,
        },
      },
      webCustomShape: {
        whileInView: {
          translateX: "-21%",
          translateY: "-100%",
          translateZ: -50,
        },
      },
      webSommersCenterpiece: {
        whileInView: {
          translateX: "-42%",
        },
      },
      webSommersShape: {
        whileInView: {
          translateX: "-159%",
          translateY: "-100%",
          translateZ: -50,
        },
      },
      webSommersCard: {
        whileInView: {
          translateX: "75%",
          translateY: "50%",
          translateZ: 50,
        },  
      },
      webSommersLogos: {
        whileInView: {
          translateX: "-450%",
          translateZ: 100,
        },
      },
      webSequoiaCenterpiece: {
        whileInView: {},
      },
      webSequoiaShape: {
        whileInView: {
          translateX: "-84%",
          translateY: "-4%",
          translateZ: -50,
        },
      },
      webSequoiaTurnaround: {
        whileInView: {
          translateX: "-27%",
          translateY: "98%",
          translateZ: 100,  
        },
      },
      localCenterpiece: {
        whileInView: {
          translateY: "-70%",
          translateZ: -50,
        },
      },
      localRating: {
        whileInView: {
          translateX: "40%",
          translateY: "-250%",
        },
      },
      localLeads: {
        whileInView: {
          translateX: "-159%",
          translateY: "92%",
        },
      },
      localWebsite: {
        whileInView: {
          translateY: "-230%",
          translateZ: 100,
        },
      },
      localCards: {
        whileInView: {
          translateX: "60px",
          translateY: "70%",
          translateZ: 50,
        },
      },
      localReviews: {
        whileInView: {
          translateX: "83%",
          translateY: "0%",
        },
      },
      searchStrategyCenterpiece: {
        whileInView: {
          translateY: "-50%",
          translateZ: -50,
        }, 
      },
      searchStrategyBadge: {
        whileInView: {
          translateZ: 100,
        },
      },
      searchStrategyResearch: { 
        whileInView: {
          translateX: "-142%",
          translateY: "-114%",
        },
      },
      searchStrategyLinks: {
        whileInView: {
          translateX: "-165%",
          translateY: "65%",
          translateZ: 50,
        },
      },
      searchStrategyContent: {
        whileInView: {
          translateX: "72%",
          translateY: "-286%",
          translateZ: 50,
        },
      },
      searchStrategyCode: {
        whileInView: {
          translateX: "65%",
          translateY: "129%",
        },
      },
      searchCompetitorCenterpiece: {
        whileInView: {
          translateX: "-70%",
          translateY: "-70%",
          translateZ: -50,
        },
      },
      searchCompetitorChart: {
        whileInView: {
          translateY: "60%",
          translateZ: 50,
        },
      },
      searchSpyCenterpiece: {
        whileInView: {},
      },
      searchSpyBadge: {
        whileInView: {
          translateX: "-32%",
          translateY: "300%",
          translateZ: 50,
        },
      },
      searchSpyShape: {
        whileInView: {
          translateX: "-20%",
          translateY: "-78%",
          translateZ: -50,
        },
      },
      searchDevCenterpiece: {
        whileInView: {
          translateY: "-250px",
          translateZ: -50,
        },
      },
      searchDevLongForm: {
        whileInView: {
          translateX: "-148px",
          translateY: "-326px",
        },
      },
      searchDevWebStories: {
        whileInView: {
          translateX: "75px",
          translateY: "-332px",
          translateZ: 50,
        },
      },
      searchDevVideos: {
        whileInView: {
          translateX: "-350px",
          translateY: "-53px",
          translateZ: 50,
        },
      },
      searchDevQandA: {
        whileInView: {
          translateX: "330px",
          translateY: "90px",
        },
      },
      searchDevInfographics: {
        whileInView: {
          translateX: "-228px",
          translateY: "164px",
        },
      },
      searchDevEbooks: {
        whileInView: {
          translateX: "279px",
          translateY: "244px",
          translateZ: 50,
        },
      },
      searchPointsCenterpiece: {
        whileInView: {
          translateX: "-60%",
          translateY: "-60%",
        },
      },
      searchPointsCard: {
        whileInView: {
          translateX: "-44%",
          translateY: "60%",
          translateZ: 50,
        },
      },
      searchPointsShape: {
        whileInView: {
          translateX: "-80%",
          translateY: "-20%",
          translateZ: -50,
        },
      },
      socialUpgradeCenterpiece: {
        whileInView: {
          translateZ: 50,
        },
      },
      socialUpgradeShape: {
        whileInView: {
          translateX: "-20%",
          translateY: "-90%",
        },
      },
      socialPressCenterpiece: {
        whileInView: {
          translateY: "-70%",
        },
      },
      socialPressCardLFNW: {
        whileInView: {
          translateX: "-100%",
          translateY: "-277%",
          translateZ: 50,
        },
      },
      socialPressBadge: {
        whileInView: {
          translateX: "40%",
          translateY: "-345%",
          translateZ: 25,
        },
      },
      socialPressCardGoogle: {
        whileInView: {
          translateX: "-250%",
          translateY: "-40%",
          translateZ: 50,
        },
      },
      socialPressCardApple: {
        whileInView: {
          translateX: "60%",
          translateY: "50%",
          translateZ: 50,
        },
      },
      socialPressVideo: {
        whileInView: {
          translateX: "-90%",
          translateY: "65%",
          translateZ: 100,
        },
      },
      socialPressWaves: {
        whileInView: {
          translateX: "-200%",
          translateY: "-90%",
          translateZ: -100,
        },
      },
      socialPressShapeTop: {
        whileInView: {
          translateX: "-130%",
          translateY: "-45%",
          translateZ: -50,
        },
      },
      socialPressShapeBottom: {
        whileInView: {
          translateX: "0%",
          translateY: "20%",
          translateZ: -50,
        },
      },
      dashboardCenterpiece: {
        whileInView: {
          translateX: "-60%",
          translateY: "-66%",
        },
      },
      dashboardColumns: {
        whileInView: {
          translateX: "-40%",
          translateY: "40%",
          translateZ: 50,
        },
      },
      dashboardShape: {
        whileInView: {
          translateX: "-80%",
          translateY: "-26%",
          translateZ: -50,
        },
      },
      nextStepsCenterpiece: {
        whileInView: {
          translateX: "-70%",
          translateY: "-70%",
        },
      },
      nextStepsInfographic: {
        whileInView: {
          translateX: "-50%",
          translateY: "60px",
          translateZ: 50,
        },
      },
      nextStepsShape: {
        whileInView: {
          translateX: "-140px",
          translateY: "-40px",
          translateZ: -50,
        },
      },
      nextBrandCenterpiece: {
        whileInView: {
          translateX: "-440px",
          translateY: "-320px",
          translateZ: -50,
        },
      },
      nextBrandLogo: {
        whileInView: {
          translateX: "0px",
          translateY: "-311px",
          translateZ: 100,
        },
      },
      nextBrandCard: {
        whileInView: {
          translateX: "-88px",
          translateY: "-244px",
          translateZ: 50,
        },
      },
      nextBrandEnvelope: {
        whileInView: {
          translateX: "-24px",
          translateY: "-100px",
        },
      },
      nextBrandDescription: {
        whileInView: {
          translateX: "-24px",
          translateY: "88px",
          translateZ: 50,
        },
      },
      nextIDkitLogo: {
        whileInView: {
          translateX: "-400px",
          translateY: "-225px",
          translateZ: 50,
        },
      },
      nextIDkitCards: {
        whileInView: {
          translateX: "-150px",
          translateY: "-322px",
        },
      },
      nextIDkitStationary: {
        whileInView: {
          translateX: "99px",
          translateY: "-199px",
          translateZ: -50,
        },
      },
    },
  },

}

export default theme